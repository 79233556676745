import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppConfig, getAppConfig } from './appConfig';

function App({ config }: { config: AppConfig }) {
  const [weather, setWeather] = useState([]);
  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    fetch(`${getAppConfig().API_BASE_URL}/weather/resultcount`, {
      method: 'GET',
      headers: {
        'Accept-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((json) => setResultCount(json))

    getWeatherForecast();
  }, []);

  let getWeatherForecast = function() {
    fetch(`${getAppConfig().API_BASE_URL}/weather`, {
      method: 'GET',
      headers: {
        'Accept-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((json) => setWeather(json.map((data: any) => data.summary)))
    .catch((err) => {
      console.log(err.message);
    });
  };

  let updateResultCount = function(newCount: number) : Promise<any> {
    return fetch(`${getAppConfig().API_BASE_URL}/weather/resultcount`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: `${newCount}`
    })
    .catch((err) => {
      console.log(err.message);
    })
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <p>
            Environment: <b>{config.REACT_APP_ENV}</b>
        </p>
        <p>
        <input id="message" type="text" value={resultCount} onChange={(event) => setResultCount(parseInt(event.target.value))} onKeyDown={(event) => { if (event.key == 'Enter') { updateResultCount(resultCount).then(a => getWeatherForecast()) } }} />
        </p>
        <p>
          {weather.map((data) => (
            <li>{data}</li>
          ))}
        </p>
      </header>
    </div>
  );
}

export default App;
